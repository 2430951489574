import React, { useState } from 'react';
import './styles/aboutCarousel.scss';

const NexabridgeCarousel = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const pages = [
    {
      title: 'NEXABRIDGE',
      content: `Nexabridge accompagne les entreprises dans leur transformation digitale et la gestion de projets IT. En tant que prestataire indépendant, nous offrons des solutions personnalisées adaptées à chaque client, garantissant flexibilité, expertise, et une approche sur mesure.`
    },
    {
      title: 'SERVICES',
      content: `Nous proposons une gamme de services allant de la gestion de projets IT au développement web, en passant 
      par l'automatisation des processus et l'intégration de solutions numériques. Nexabridge assure la réussite des projets 
      grâce à une expertise pointue et un suivi personnalisé.`
    },
    {
      title: 'VALEURS',
      content: `Chez Nexabridge, chaque projet est unique. Nous travaillons en étroite collaboration avec nos clients pour offrir 
      des solutions sur mesure et un accompagnement personnalisé. Notre objectif est d'assurer la réussite des projets tout en 
      répondant aux enjeux digitaux de nos partenaires, avec rigueur et engagement.`
    }
  ];

  const handleClickOnProgress = (event) => {
    const progressBar = event.target;
    const boundingRect = progressBar.getBoundingClientRect();
    const clickX = event.clientX - boundingRect.left;
    const width = boundingRect.width;
    const newIndex = Math.floor((clickX / width) * pages.length); // Calcule l'index à partir de la position du clic
    setActiveIndex(newIndex);
  };

  return (
    <div className="nexabridge-carousel">
      {/* Barre de progression cliquable au-dessus */}
      <div 
        className="nexabridge-carousel__progress" 
        onClick={handleClickOnProgress}
      >
        <div 
          className="nexabridge-carousel__progress-line"
          style={{
            width: `${(activeIndex + 1) * (100 / pages.length)}%`,
            backgroundColor: 'white' // Couleur Nexabridge
          }}
        ></div>
      </div>

      {/* Contenu du carousel en dessous de la barre de progression */}
      <div className="nexabridge-carousel__content">
        <h2>{pages[activeIndex].title}</h2>
        <p>{pages[activeIndex].content}</p>
      </div>
    </div>
  );
};

export default NexabridgeCarousel;