import BlogImg1 from '../Images/freelance-tech.png';
import BlogImg2 from '../Images/agence-web.png';
import BlogImg3 from '../Images/strategie-digitale.png';
import BlogImg4 from '../Images/dev-web-modern.jpg';
import BlogImg5 from '../Images/project-it.png';

export const blogData = [
  { 
    id: 1,
    title: "Les avantages du travail indépendant dans la tech",
    date: new Date("2024-02-15"),
    img: BlogImg1,
    excerpt: "Découvrez pourquoi le statut de freelance dans la tech est une option attractive pour les professionnels du numérique.",
    content: `
      <p>Le <strong>travail indépendant</strong> est en pleine expansion, surtout dans la tech, où les freelances jouent un rôle clé dans le développement de projets innovants. Travailler en tant que <span class="highlight">freelance</span> offre plusieurs avantages, notamment la liberté de choisir ses projets, une flexibilité dans la gestion de son emploi du temps, et la possibilité d'optimiser ses revenus en fonction des compétences offertes.</p>
      
      <p>Dans le domaine de la tech, les entreprises ont besoin de solutions rapides et agiles. Les <span class="highlight">freelances</span> leur apportent cette flexibilité en proposant des compétences spécialisées sur des périodes définies. Par exemple, chez Nexabridge, nous avons accompagné de nombreuses entreprises dans leur transformation digitale en prenant en charge des projets de développement web, de gestion de projets IT, et d'optimisation SEO, tout en leur offrant l'agilité qu'un travailleur indépendant peut apporter.</p>
      
      <p>Le freelancing dans la tech permet également de négocier des tarifs compétitifs tout en vous permettant d'élargir votre réseau professionnel. Cela vous ouvre également la possibilité de travailler avec des clients internationaux, ce qui peut être très enrichissant. Vous souhaitez en savoir plus sur comment vous pouvez bénéficier de nos services freelances chez Nexabridge ? Contactez-nous pour en discuter.</p>
    `
  },
  { 
    id: 2,
    title: "Comment une agence web peut transformer votre entreprise",
    date: new Date("2024-01-10"),
    img: BlogImg2,
    excerpt: "Les agences web sont des partenaires essentiels pour réussir dans le monde numérique. Découvrez comment elles peuvent vous aider.",
    content: `
      <p>Dans le paysage numérique actuel, une <strong>présence en ligne</strong> est essentielle pour la croissance de toute entreprise. Cependant, la création et la gestion d'une telle présence nécessitent une expertise technique que toutes les entreprises ne possèdent pas en interne. C'est là qu'une <span class="highlight">agence web</span> entre en jeu.</p>
      
      <p>Chez Nexabridge, nous ne nous contentons pas de créer des sites web, nous construisons des <span class="highlight">expériences digitales</span> qui favorisent la conversion et l'engagement. Que vous ayez besoin d'un site e-commerce performant, d'une application web ou d'une optimisation de votre référencement naturel, nous concevons des solutions sur mesure adaptées à vos objectifs d'affaires. Nous assurons également la maintenance de votre site pour garantir une <strong>performance continue</strong>.</p>
      
      <p>Une agence web comme Nexabridge vous permet de rester concentré sur votre cœur de métier tout en vous garantissant une visibilité optimale sur le web. Si vous souhaitez augmenter vos conversions, améliorer votre SEO ou simplement créer une présence en ligne professionnelle, nous sommes le partenaire qu'il vous faut.</p>
    `
  },
  { 
    id: 3,
    title: "Stratégie digitale : pourquoi votre entreprise en a besoin",
    date: new Date("2023-12-22"),
    img: BlogImg3,
    excerpt: "Une stratégie digitale efficace est la clé pour réussir dans le monde numérique d'aujourd'hui. Voici pourquoi.",
    content: `
      <p>De nos jours, la <strong>transformation digitale</strong> n'est plus une option mais une nécessité pour les entreprises qui souhaitent se maintenir compétitives. Mais qu'est-ce qu'une stratégie digitale efficace ? Il s'agit d'un plan global qui englobe différents aspects de votre présence en ligne : <span class="highlight">SEO</span>, <span class="highlight">réseaux sociaux</span>, <span class="highlight">publicité en ligne</span>, et bien plus encore.</p>
      
      <p>Chez Nexabridge, nous analysons d'abord vos objectifs d'affaires et votre marché avant de vous proposer une stratégie digitale complète. Cela peut inclure l'amélioration de votre visibilité sur les moteurs de recherche, la création de campagnes de marketing ciblées, et la gestion de vos réseaux sociaux pour renforcer votre image de marque. Avec une stratégie bien conçue, vous pouvez non seulement <span class="highlight">attirer plus de clients</span> mais aussi améliorer leur expérience, augmentant ainsi vos chances de conversion.</p>
      
      <p>Ne laissez pas votre entreprise prendre du retard dans le monde numérique. Contactez Nexabridge pour développer une stratégie digitale qui vous placera en tête de votre secteur.</p>
    `
  },
  { 
    id: 4,
    title: "Développement web moderne : les clés pour 2024",
    date: new Date("2023-11-30"),
    img: BlogImg4,
    excerpt: "Découvrez les technologies et les pratiques à adopter en 2024 pour rester à la pointe du développement web.",
    content: `
      <p>Le <strong>développement web</strong> continue d'évoluer rapidement avec l'introduction de nouvelles technologies, de nouveaux langages et des attentes accrues des utilisateurs. En 2024, plusieurs tendances dominent le paysage du développement web, et chez Nexabridge, nous les adoptons pour vous garantir une solution à la pointe de la technologie.</p>
      
      <p>Tout d'abord, l'optimisation de la <span class="highlight">performance des sites</span> est essentielle. Les utilisateurs ne tolèrent plus les temps de chargement longs, et Google favorise désormais les sites qui se chargent rapidement. Chez Nexabridge, nous utilisons des techniques comme les <strong>PWA (Progressive Web Apps)</strong> et des frameworks modernes comme <strong>React</strong> pour garantir que vos sites offrent la meilleure expérience possible à vos utilisateurs.</p>
      
      <p>De plus, la sécurité est devenue une priorité absolue. Nos solutions incluent des pratiques de <strong>ségrégation des données</strong>, d'<strong>authentification forte</strong>, et de <strong>protection contre les cyberattaques</strong>, garantissant que vos utilisateurs et vos données restent en sécurité.</p>
      
      <p>Si vous avez un projet de développement web ou souhaitez moderniser votre site actuel, contactez Nexabridge. Nous avons l'expertise nécessaire pour vous fournir des solutions modernes, sécurisées et performantes.</p>
    `
  },
  { 
    id: 5,
    title: "Pourquoi la gestion de projets IT est essentielle",
    date: new Date("2023-10-15"),
    img: BlogImg5,
    excerpt: "La gestion de projets IT est cruciale pour garantir la réussite de vos projets technologiques. Voici pourquoi.",
    content: `
      <p>La <strong>gestion de projets IT</strong> est souvent négligée, mais elle joue un rôle crucial dans la réussite de vos initiatives technologiques. De la phase de planification au déploiement, chaque étape doit être soigneusement gérée pour éviter les retards et les dépassements de budget.</p>
      
      <p>Chez Nexabridge, nous sommes experts dans la gestion de projets IT complexes. Nous veillons à ce que les ressources soient allouées de manière optimale, que toutes les parties prenantes soient informées à chaque étape du projet, et que les objectifs soient atteints dans les délais impartis.</p>
      
      <p>Nos méthodologies de gestion de projet, basées sur des principes agiles, permettent à vos projets de s'adapter aux évolutions du marché et aux nouvelles exigences tout en respectant les délais. Nous garantissons que votre projet sera livré selon vos spécifications, sans mauvaises surprises.</p>
      
      <p>Si vous avez un projet IT en cours ou à venir, faites appel à Nexabridge pour vous assurer qu'il est bien géré de bout en bout. Nous mettons notre expertise au service de votre réussite.</p>
    `
  }
];

export default blogData;